/* @import url("https://fonts.googleapis.com/css?family=Quicksand:400,500,600,700&display=swap");
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,500,600,700&display=swap"); */
@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,600,700|Quicksand:700&display=swap&subset=vietnamese");
@import url("https://fonts.googleapis.com/css?family=Raleway:100,600");
@font-face {
    font-family: "devasIcon";
    src: url("../font/DevasPubLogo/fontello.eot?13913418");
    src: url("../font/DevasPubLogo/fontello.eot?13913418#iefix") format("embedded-opentype"),
        url("../font/DevasPubLogo/fontello.woff?13913418") format("woff"),
        url("../font/DevasPubLogo/fontello.ttf?13913418") format("truetype"),
        url("../font/DevasPubLogo/fontello.svg?13913418#fontello") format("svg");
    font-weight: normal;
    font-style: normal;
}
.light-theme {
    --primary: #f0541e;
    --secondary: #b24289;
    --secondary-light: #d8a0c4;
    --text: #2f4858;
    --box-shadow: 6px 0 18px 0 rgba(0, 0, 0, 0.05);
    --red: #e52d27;
    --green: #27ae60;
    --blue: #2f80ed;
    --orange: #ff8f39;
    --coral: #eb5757;
    --gray: #a7a9bc;
    --button-disabled: #dbd8e3;
    --checkbox-bg: #3d517c;
    --inverse: #3d517c;
    --border-default: #e7e7ed;
    --bg-auth: #f9f9f9;
    --bg-block-inverse: #f5f8fd;
    --bg-menu: #ffffff;
    --list-active: #a6a8b9;
    --dark-gray: #6c757d;
    --btn-table-default: #2f4858;
    --btn-table-focus: rgba(0, 0, 0, 0.12);
    --btn-table-hover: rgba(0, 0, 0, 0.12);
    --btn-table-disabled: rgba(0, 0, 0, 0.12);
    --nav-btn: rgba(0, 0, 0, 0.54);
}
.dark-theme {
    --primary: #ff7844;
    --secondary: #dc5d9d;
    /* 8b4367 */
    --secondary-light: #d8a0c4;
    --text: #dbd8e3;
    --box-shadow: 6px 0 18px 0 rgba(0, 0, 0, 0.25);
    --red: #e52d27;
    --green: #27ae60;
    --blue: #2f80ed;
    --orange: #ff7844;
    --coral: #eb5757;
    --gray: #a7a9bc;
    --button-disabled: #dbd8e3;
    --checkbox-bg: #3d517c;
    --inverse: #3d517c;
    --border-default: #5b5a5a;
    --bg-auth: #1b2039;
    --bg-block-inverse: #5a65b0;
    --bg-menu: #262b4b;
    --list-active: #a6a8b9;
    --dark-gray: #6c757d;
    --btn-table-default: #dbd8e3;
    --btn-table-focus: rgba(255, 255, 255, 0.54);
    --btn-table-hover: rgba(255, 255, 255, 0.12);
    --btn-table-disabled: rgba(0, 0, 0, 0.12);
    --nav-btn: rgba(211, 211, 211, 0.54);
}
.icon-devas {
    font-family: "devasIcon";
    font-style: normal;
    font-weight: 400;
    font-variant: normal;
    display: inline-block;
    text-decoration: inherit;
    text-align: center;
    font-variant: normal;
    text-transform: none;
    line-height: 24px;
    font-size: 20px;
    width: 24px !important;
    height: 24px !important;
}
.icon-home:before {
    content: "\e800";
} /* '' */
.icon-invoice:before {
    content: "\e801";
} /* '' */
.icon-knowledge:before {
    content: "\e802";
} /* '' */
.icon-offers:before {
    content: "\e803";
} /* '' */
.icon-postback:before {
    content: "\e804";
} /* '' */
.icon-statistics:before {
    content: "\e805";
} /* '' */
.icon-tools:before {
    content: "\e806";
} /* '' */
.icon-toggle:before {
    content: "\e807";
} /* '' */
.icon-edit:before {
    content: "\e808";
} /* '' */
.icon-dropdown:before {
    content: "\e809";
} /* '' */
.icon-copy:before {
    content: "\e80a";
} /* '' */
.icon-small {
    font-size: 12px;
}
.icon-pointer {
    cursor: pointer;
}
.icon-edit:hover {
    color: var(--secondary);
}
html {
    font-size: 13.5px;
}
body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
        "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
.table td,
.table th {
    vertical-align: middle;
}
.box-shadow {
    box-shadow: var(--box-shadow);
}
.form-group {
    margin-bottom: 1.5rem;
    vertical-align: center;
}
.form-control {
    box-shadow: var(--box-shadow);
    border-radius: 0px;
    color: var(--text);
    border: none;
    height: 2.75rem;
    background-color: var(--bg-menu);
}
.form-control:active,
.form-control:focus {
    background-color: var(--bg-menu);
    color: var(--text);
}
.normal-form .form-group {
    margin-bottom: 20px;
}
.normal-form .form-control {
    box-shadow: none;
    border: 1px solid var(--border-default);
    height: 2.4rem;
    font-size: 0.8rem;
}
.normal-form textarea.form-control {
    height: auto;
}
.normal-form label {
    font-size: 0.8rem;
    font-weight: 600;
}
.small-text {
    font-size: 0.85rem;
}
.normal-form .checkbox-container + label {
    font-size: 0.8rem;
    font-weight: 400;
}
.normal-form .checkbox-container .checkmark:after {
    left: 5px;
    top: 1px;
}
.normal-form .checkmark {
    border-width: 1px;
    background-color: var(--bg-menu);
    height: 16px;
    width: 16px;
    margin-top: 5.5px;
}
.checkbox-container {
    display: block;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    margin: 0;
}
.a-link {
    font-size: 0.8rem;
    color: var(--text);
}
.a-link:hover {
    text-decoration: none;
    color: var(--primary);
}
/* Hide the browser's default checkbox */
.checkbox-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    margin-top: 2px;
    border-style: solid;
    border-radius: 0.125rem;
    border-width: 2px;
    background-color: var(--bg-auth);
}

/* When the checkbox is checked, add a blue background */
.checkbox-container input:checked ~ .checkmark {
    background-color: var(--checkbox-bg);
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.checkbox-container input:checked ~ .checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.checkbox-container .checkmark:after {
    left: 6px;
    top: 3px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.bg-main {
    background-color: var(--bg-auth);
    color: var(--text);
    border-color: var(--border-defaul);
    font-family: "Open Sans", sans-serif;
}

.bg-menu {
    background-color: var(--bg-menu);
    box-shadow: var(--box-shadow);
    color: var(--text);
    border-color: var(--border-defaul);
}
.mw-100 {
    min-height: 100vh;
}
.w-checkbox {
    width: 11rem;
}
.button-devas {
    border-color: var(--secondary);
    border-radius: 1.25rem;
    border-width: 1px;
    font-size: 0.7rem;
    color: var(--secondary);
    font-weight: 700;
}
.button-devas-medium {
    border-color: var(--secondary);
    border-radius: 1.25rem;
    border-width: 1px;
    font-size: 0.8rem;
    color: var(--secondary);
    font-weight: 700;
}
.button-devas-large {
    border-color: var(--secondary);
    border-radius: 3rem;
    border-width: 1px;
    font-size: 0.8rem;
    color: var(--secondary);
    font-weight: 700;
    width: 4.5rem;
}
.button-devas:hover,
.button-devas-medium:hover,
.button-devas-large:hover {
    color: var(--bg-menu);
    background-color: var(--secondary);
}
.button-devas-gray {
    border-color: var(--dark-gray);
    border-radius: 1.25rem;
    border-width: 1px;
    font-size: 0.7rem;
    color: var(--dark-gray);
    font-weight: 700;
}
.button-devas-gray-large {
    border-color: var(--dark-gray);
    border-radius: 3rem;
    border-width: 1px;
    font-size: 0.8rem;
    color: var(--dark-gray);
    font-weight: 700;
    width: 4.5rem;
}
.button-devas-gray-large:hover,
.button-devas-gray:hover {
    color: var(--bg-menu);
    background-color: var(--dark-gray);
}
.button-devas-danger {
    border-color: var(--red);
    border-radius: 1.25rem;
    border-width: 1px;
    font-size: 0.7rem;
    color: var(--red);
    font-weight: 700;
}
.button-devas-danger:hover {
    color: var(--bg-menu);
    background-color: var(--red);
}
.table {
    color: var(--text);
}
.table thead th {
    border-color: var(--bg-block-inverse);
    background-color: var(--bg-block-inverse);
    font-size: 0.8rem;
    padding: 8px 12px;
}
.table td {
    border-top: 2px solid var(--bg-block-inverse);
    font-size: 0.8rem;
}
.copyright {
    width: 100%;
    margin: 24px 0px;
    text-align: center;
    color: var(--text);
    font-weight: 600;
}
.copyright span {
    color: var(--primary);
}
.w-100vw {
    width: 100vw;
}
.h-100vh {
    height: 100vh;
}
.font-raleway {
    font-family: "Raleway", sans-serif;
}
.text-color {
    color: var(--text);
}
.fade-out-transition {
    -webkit-transition: opacity 0.3s ease-in-out;
    -moz-transition: opacity 0.3s ease-in-out;
    -ms-transition: opacity 0.3s ease-in-out;
    -o-transition: opacity 0.3s ease-in-out;
    transition: opacity 0.3s ease-in-out;
}
.opacity-0 {
    opacity: 0;
}
.opacity-1 {
    opacity: 1;
}
.table-ul {
    padding-inline-start: 17px;
    list-style-type: circle;
}
.hover-primary {
    color: var(--text);
}
.hover-primary:hover {
    color: var(--primary);
}
@media (min-width: 576px) {
    html {
        font-size: 16px;
    }
    .button-devas-large {
        border-color: var(--secondary);
        border-radius: 3rem;
        border-width: 1px;
        font-size: 1rem;
        color: var(--secondary);
        font-weight: 700;
        width: 9rem;
        padding: 7px 0px;
    }
    .button-devas-gray-large {
        border-color: var(--dark-gray);
        border-radius: 3rem;
        border-width: 1px;
        font-size: 1rem;
        color: var(--dark-gray);
        font-weight: 700;
        width: 9rem;
        padding: 7px 0px;
    }
    .button-devas-medium {
        border-color: var(--secondary);
        border-radius: 1.25rem;
        border-width: 1px;
        font-size: 0.85rem;
        color: var(--secondary);
        font-weight: 700;
        padding: 7px 22px;
    }
}
