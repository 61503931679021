.react-datepicker {
    background-color: var(--bg-menu);
    color: var(--text);
    border: 1px solid var(--border-defaul);
    border-radius: 0.3rem;
    display: inline-block;
    position: relative;
    box-shadow: var(--box-shadow);
}
.react-datepicker__header {
    background-color: var(--bg-menu);
    color: var(--text);
}

.react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected{
    background-color: var(--secondary);
    color: var(--text);
}

.react-datepicker__month-text.react-datepicker__month--selected:hover, .react-datepicker__month-text.react-datepicker__month--in-range:hover, .react-datepicker__month-text.react-datepicker__quarter--selected:hover, .react-datepicker__month-text.react-datepicker__quarter--in-range:hover, .react-datepicker__quarter-text.react-datepicker__month--selected:hover, .react-datepicker__quarter-text.react-datepicker__month--in-range:hover, .react-datepicker__quarter-text.react-datepicker__quarter--selected:hover, .react-datepicker__quarter-text.react-datepicker__quarter--in-range:hover{
    background-color: var(--secondary-light);
    color: var(--text);
}
.react-datepicker__month-text:hover, .react-datepicker__quarter-text:hover {
    background-color: var(--secondary-light);
    color: var(--text);
}