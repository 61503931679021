@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,600,700|Quicksand:700&display=swap&subset=vietnamese);
@import url(https://fonts.googleapis.com/css?family=Raleway:100,600);
/* @import url("https://fonts.googleapis.com/css?family=Quicksand:400,500,600,700&display=swap");
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,500,600,700&display=swap"); */
@font-face {
    font-family: "devasIcon";
    src: url(/static/media/fontello.a3e7efb5.eot);
    src: url(/static/media/fontello.a3e7efb5.eot#iefix) format("embedded-opentype"),
        url(/static/media/fontello.8a4a6c11.woff) format("woff"),
        url(/static/media/fontello.0974587d.ttf) format("truetype"),
        url(/static/media/fontello.73590cd8.svg#fontello) format("svg");
    font-weight: normal;
    font-style: normal;
}
.light-theme {
    --primary: #f0541e;
    --secondary: #b24289;
    --secondary-light: #d8a0c4;
    --text: #2f4858;
    --box-shadow: 6px 0 18px 0 rgba(0, 0, 0, 0.05);
    --red: #e52d27;
    --green: #27ae60;
    --blue: #2f80ed;
    --orange: #ff8f39;
    --coral: #eb5757;
    --gray: #a7a9bc;
    --button-disabled: #dbd8e3;
    --checkbox-bg: #3d517c;
    --inverse: #3d517c;
    --border-default: #e7e7ed;
    --bg-auth: #f9f9f9;
    --bg-block-inverse: #f5f8fd;
    --bg-menu: #ffffff;
    --list-active: #a6a8b9;
    --dark-gray: #6c757d;
    --btn-table-default: #2f4858;
    --btn-table-focus: rgba(0, 0, 0, 0.12);
    --btn-table-hover: rgba(0, 0, 0, 0.12);
    --btn-table-disabled: rgba(0, 0, 0, 0.12);
    --nav-btn: rgba(0, 0, 0, 0.54);
}
.dark-theme {
    --primary: #ff7844;
    --secondary: #dc5d9d;
    /* 8b4367 */
    --secondary-light: #d8a0c4;
    --text: #dbd8e3;
    --box-shadow: 6px 0 18px 0 rgba(0, 0, 0, 0.25);
    --red: #e52d27;
    --green: #27ae60;
    --blue: #2f80ed;
    --orange: #ff7844;
    --coral: #eb5757;
    --gray: #a7a9bc;
    --button-disabled: #dbd8e3;
    --checkbox-bg: #3d517c;
    --inverse: #3d517c;
    --border-default: #5b5a5a;
    --bg-auth: #1b2039;
    --bg-block-inverse: #5a65b0;
    --bg-menu: #262b4b;
    --list-active: #a6a8b9;
    --dark-gray: #6c757d;
    --btn-table-default: #dbd8e3;
    --btn-table-focus: rgba(255, 255, 255, 0.54);
    --btn-table-hover: rgba(255, 255, 255, 0.12);
    --btn-table-disabled: rgba(0, 0, 0, 0.12);
    --nav-btn: rgba(211, 211, 211, 0.54);
}
.icon-devas {
    font-family: "devasIcon";
    font-style: normal;
    font-weight: 400;
    font-feature-settings: normal;
    font-variant: normal;
    display: inline-block;
    text-decoration: inherit;
    text-align: center;
    font-variant: normal;
    text-transform: none;
    line-height: 24px;
    font-size: 20px;
    width: 24px !important;
    height: 24px !important;
}
.icon-home:before {
    content: "\e800";
} /* '' */
.icon-invoice:before {
    content: "\e801";
} /* '' */
.icon-knowledge:before {
    content: "\e802";
} /* '' */
.icon-offers:before {
    content: "\e803";
} /* '' */
.icon-postback:before {
    content: "\e804";
} /* '' */
.icon-statistics:before {
    content: "\e805";
} /* '' */
.icon-tools:before {
    content: "\e806";
} /* '' */
.icon-toggle:before {
    content: "\e807";
} /* '' */
.icon-edit:before {
    content: "\e808";
} /* '' */
.icon-dropdown:before {
    content: "\e809";
} /* '' */
.icon-copy:before {
    content: "\e80a";
} /* '' */
.icon-small {
    font-size: 12px;
}
.icon-pointer {
    cursor: pointer;
}
.icon-edit:hover {
    color: var(--secondary);
}
html {
    font-size: 13.5px;
}
body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
        "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
.table td,
.table th {
    vertical-align: middle;
}
.box-shadow {
    box-shadow: var(--box-shadow);
}
.form-group {
    margin-bottom: 1.5rem;
    vertical-align: center;
}
.form-control {
    box-shadow: var(--box-shadow);
    border-radius: 0px;
    color: var(--text);
    border: none;
    height: 2.75rem;
    background-color: var(--bg-menu);
}
.form-control:active,
.form-control:focus {
    background-color: var(--bg-menu);
    color: var(--text);
}
.normal-form .form-group {
    margin-bottom: 20px;
}
.normal-form .form-control {
    box-shadow: none;
    border: 1px solid var(--border-default);
    height: 2.4rem;
    font-size: 0.8rem;
}
.normal-form textarea.form-control {
    height: auto;
}
.normal-form label {
    font-size: 0.8rem;
    font-weight: 600;
}
.small-text {
    font-size: 0.85rem;
}
.normal-form .checkbox-container + label {
    font-size: 0.8rem;
    font-weight: 400;
}
.normal-form .checkbox-container .checkmark:after {
    left: 5px;
    top: 1px;
}
.normal-form .checkmark {
    border-width: 1px;
    background-color: var(--bg-menu);
    height: 16px;
    width: 16px;
    margin-top: 5.5px;
}
.checkbox-container {
    display: block;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    margin: 0;
}
.a-link {
    font-size: 0.8rem;
    color: var(--text);
}
.a-link:hover {
    text-decoration: none;
    color: var(--primary);
}
/* Hide the browser's default checkbox */
.checkbox-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    margin-top: 2px;
    border-style: solid;
    border-radius: 0.125rem;
    border-width: 2px;
    background-color: var(--bg-auth);
}

/* When the checkbox is checked, add a blue background */
.checkbox-container input:checked ~ .checkmark {
    background-color: var(--checkbox-bg);
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.checkbox-container input:checked ~ .checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.checkbox-container .checkmark:after {
    left: 6px;
    top: 3px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}

.bg-main {
    background-color: var(--bg-auth);
    color: var(--text);
    border-color: var(--border-defaul);
    font-family: "Open Sans", sans-serif;
}

.bg-menu {
    background-color: var(--bg-menu);
    box-shadow: var(--box-shadow);
    color: var(--text);
    border-color: var(--border-defaul);
}
.mw-100 {
    min-height: 100vh;
}
.w-checkbox {
    width: 11rem;
}
.button-devas {
    border-color: var(--secondary);
    border-radius: 1.25rem;
    border-width: 1px;
    font-size: 0.7rem;
    color: var(--secondary);
    font-weight: 700;
}
.button-devas-medium {
    border-color: var(--secondary);
    border-radius: 1.25rem;
    border-width: 1px;
    font-size: 0.8rem;
    color: var(--secondary);
    font-weight: 700;
}
.button-devas-large {
    border-color: var(--secondary);
    border-radius: 3rem;
    border-width: 1px;
    font-size: 0.8rem;
    color: var(--secondary);
    font-weight: 700;
    width: 4.5rem;
}
.button-devas:hover,
.button-devas-medium:hover,
.button-devas-large:hover {
    color: var(--bg-menu);
    background-color: var(--secondary);
}
.button-devas-gray {
    border-color: var(--dark-gray);
    border-radius: 1.25rem;
    border-width: 1px;
    font-size: 0.7rem;
    color: var(--dark-gray);
    font-weight: 700;
}
.button-devas-gray-large {
    border-color: var(--dark-gray);
    border-radius: 3rem;
    border-width: 1px;
    font-size: 0.8rem;
    color: var(--dark-gray);
    font-weight: 700;
    width: 4.5rem;
}
.button-devas-gray-large:hover,
.button-devas-gray:hover {
    color: var(--bg-menu);
    background-color: var(--dark-gray);
}
.button-devas-danger {
    border-color: var(--red);
    border-radius: 1.25rem;
    border-width: 1px;
    font-size: 0.7rem;
    color: var(--red);
    font-weight: 700;
}
.button-devas-danger:hover {
    color: var(--bg-menu);
    background-color: var(--red);
}
.table {
    color: var(--text);
}
.table thead th {
    border-color: var(--bg-block-inverse);
    background-color: var(--bg-block-inverse);
    font-size: 0.8rem;
    padding: 8px 12px;
}
.table td {
    border-top: 2px solid var(--bg-block-inverse);
    font-size: 0.8rem;
}
.copyright {
    width: 100%;
    margin: 24px 0px;
    text-align: center;
    color: var(--text);
    font-weight: 600;
}
.copyright span {
    color: var(--primary);
}
.w-100vw {
    width: 100vw;
}
.h-100vh {
    height: 100vh;
}
.font-raleway {
    font-family: "Raleway", sans-serif;
}
.text-color {
    color: var(--text);
}
.fade-out-transition {
    transition: opacity 0.3s ease-in-out;
}
.opacity-0 {
    opacity: 0;
}
.opacity-1 {
    opacity: 1;
}
.table-ul {
    -webkit-padding-start: 17px;
            padding-inline-start: 17px;
    list-style-type: circle;
}
.hover-primary {
    color: var(--text);
}
.hover-primary:hover {
    color: var(--primary);
}
@media (min-width: 576px) {
    html {
        font-size: 16px;
    }
    .button-devas-large {
        border-color: var(--secondary);
        border-radius: 3rem;
        border-width: 1px;
        font-size: 1rem;
        color: var(--secondary);
        font-weight: 700;
        width: 9rem;
        padding: 7px 0px;
    }
    .button-devas-gray-large {
        border-color: var(--dark-gray);
        border-radius: 3rem;
        border-width: 1px;
        font-size: 1rem;
        color: var(--dark-gray);
        font-weight: 700;
        width: 9rem;
        padding: 7px 0px;
    }
    .button-devas-medium {
        border-color: var(--secondary);
        border-radius: 1.25rem;
        border-width: 1px;
        font-size: 0.85rem;
        color: var(--secondary);
        font-weight: 700;
        padding: 7px 22px;
    }
}


.HomePage {
    background: var(--bg-auth);
    min-height: 100vh;
    height: 100%;
}
.HomePage .container {
    background-color: var(--bg-auth);
    width: 90%;
    max-width: 900px;
    padding: 45px 0px;
    color: var(--text);
}
.HomePage .logo {
    object-fit: cover;
    width: 44px;
    height: 44px;
}
.HomePage .text-header {
    margin: 0px;
    color: var(--primary);
    font-family: "Quicksand", sans-serif;
    font-weight: 700;
    font-size: 30px;
}
.item-width {
    width: 90%;
    margin: auto;
    max-width: 300px;
}
.login-form {
    padding-top: 45px;
}
.HomePage .form-group label {
    font-weight: 600;
}
.btn-submit {
    background-color: var(--primary);
    width: 100%;
    border-radius: 1.25rem;
    height: 2.5rem;
    color: white;
    font-weight: 600;
    margin-top: 0.35rem;
    font-family: "Open Sans", sans-serif;
}
.btn-submit.disabled {
    background-color: var(--button-disabled);
}
.btn-submit:hover {
    color: white;
}
.link-tag {
    color: var(--text);
    font-weight: 600;
    font-family: "Open Sans", sans-serif;
}
.link-tag:hover {
    color: var(--text);
    text-decoration: none;
}
.link-tag.link-primary {
    color: var(--primary);
}
.link-tag.link-primary:hover {
    color: var(--primary);
}
.HomePage .login-home-page, .HomePage .login-page {
    height: 100vh;
}
.HomePage .logo-title{
    cursor: pointer;
}
@media (min-width: 576px) {
    .HomePage .login-home-page {
        height: 70%;
        max-height: 450px;
    }
    .HomePage .login-page{
        height: auto;
    }
    .HomePage {
        background: url(/static/media/loginbackground.fccbd021.jpg) 50% / cover no-repeat;
        height: 100vh;
    }
    .item-width {
        width: 60%;
        max-width: 400px;
    }
    .HomePage .container.login-home-page{
        width: 80%;
        max-width: 700px
    }
}


.Dropdown-root {
    height: 100%;
}
.dropdown-form .Dropdown-root {
    background-color: var(--bg-menu);
}
.Dropdown-control {
    border: none;
    box-shadow: var(--box-shadow);
    padding-left: 16px;
    height: 100%;
    display: flex;
    align-items: center;
    background-color: var(--bg-menu);
}
.dropdown-form .Dropdown-control {
    border: 1px solid var(--border-default);
    box-shadow: none;
}
.Dropdown-control:hover {
    box-shadow: var(--box-shadow);
}
.Dropdown-placeholder {
    font-size: 0.8rem;
    color: var(--text);
    font-family: "Open Sans", sans-serif;
}
.Dropdown-menu {
    border: none;
    font-size: 0.8rem;
    font-weight: 600;
    font-family: "Open Sans", sans-serif;
    background-color: var(--bg-menu);
}
.Dropdown-option {
    padding-left: 16px;
    color: var(--text);
}
.Dropdown-option:hover {
    background-color: var(--secondary);
    color: var(--bg-menu);
}
.Dropdown-option.is-selected {
    background-color: var(--secondary);
    color: var(--bg-menu);
}
.Dropdown-arrow {
    margin-top: 3px;
}
.devas-select {
    color: var(--text);
    font-size: 0.8rem;
}
.dropdown-form .devas-select {
    border: 1px solid var(--border-default);
}
.devas-select .devas-select__placeholder {
    color: var(--text);
    font-size: 0.8rem;
    outline: none;
}
.devas-select .devas-select__control--is-focused{
    border:none;
}
.dropdown-form .devas-select .devas-select__control--is-focused{
    border: 1px solid var(--border-default);
}
.devas-select .devas-select__control {
    border-radius: 0;
    border: none;
}

.bg-daterangepicker {
    background-color: var(--bg-menu);
    box-shadow: var(--box-shadow);
    color: var(--text);
    border-color: var(--border-defaul);
    height: 38px;
}
.daterange-form .bg-daterangepicker {
    box-shadow: none;
    border: 1px solid var(--border-default);
}
.react-daterange-picker {
    width: 100%;
}
.react-daterange-picker__wrapper {
    border: none;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    padding: 0px 8px 0px 16px;
}
.react-daterange-picker__button:focus {
    outline: none;
}
.react-daterange-picker__button .react-daterange-picker__button__icon {
    stroke: var(--text);
}
.react-daterange-picker__button:enabled:hover .react-daterange-picker__button__icon,
.react-daterange-picker__button:enabled:focus .react-daterange-picker__button__icon {
    stroke: var(--secondary);
}
.react-daterange-picker__inputGroup {
    display: flex;
    justify-content: center;
    font-size: 0.45rem;
}
.react-daterange-picker__range-divider {
    font-size: 0.45rem;
    display: flex;
    align-items: center;
}
.react-calendar__tile--active {
    background: var(--secondary);
}
.react-calendar__tile--hasActive {
    background: var(--secondary-light);
}
.react-calendar__tile--hasActive:hover {
    background: var(--secondary-light);
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
    background: var(--secondary-light);
}
.react-daterange-picker__inputGroup__input {
    color: var(--text);
}
@media (min-width: 576px) {
    .react-daterange-picker__inputGroup {
        font-size: 0.5rem;
    }
    .react-daterange-picker__range-divider {
        font-size: 0.5rem;
    }
}
@media (min-width: 1200px) {
    .react-daterange-picker__inputGroup {
        font-size: 0.6rem;
    }
    .react-daterange-picker__range-divider {
        font-size: 0.6rem;
    }
}
@media (min-width: 1350px) {
    .
    {
        font-size: 0.8rem;
    }
    .react-daterange-picker__range-divider {
        font-size: 0.8rem;
    }
}
.react-calendar__navigation button {
    color: var(--text);
}
.react-calendar--selectRange .react-calendar__tile--hover {
    color: var(--gray);
}
.react-calendar__month-view__days__day--weekend {
    color: var(--red) !important;
}
.react-calendar button {
    color: var(--text);
}
.react-calendar {
    background-color: var(--bg-menu);
    border-color: var(--bg-menu);
}
.border-grey{
    border: 1px solid var(--border-default);
}

.sidebar-col {
    flex: 0 0 314px;
    z-index: 10;
}
.Sidebar {
    position: absolute;
    top: 0;
    left: 0;
    width: 314px;
    height: 100%;
}

.sticky-sidebar {
    position: fixed;
    top: 0;
    left: 0;
}
.sidebar-header {
    height: 60px;
}
.navbar-header {
    height: 42px;
}
.header-logo {
    cursor: pointer;
}
.header-text {
    font-size: 1.125rem;
    font-weight: 700;
    font-family: "Quicksand", sans-serif;
    color: var(--primary);
    margin-bottom: 0;
    cursor: pointer;
}
.sidebar-div {
    height: 70px;
    padding: 0px 24px;
}
.sidebar-user {
    background-color: var(--bg-block-inverse);
}
.user-logo {
    height: 48px;
    width: 48px;
}
.sidebar-username {
    font-size: 1rem;
    font-family: "Open Sans", sans-serif;
    margin-bottom: 0.4rem;
    font-weight: 600;
}
.sidebar-email {
    font-size: 0.75rem;
    font-weight: 400;
    font-family: "Open Sans", sans-serif;
}
.cursor-pointer {
    cursor: pointer;
}
.sidebar-icon {
    font-family: "devasIcon";
    font-style: normal;
    font-weight: 400;
    font-feature-settings: normal;
    font-variant: normal;
    display: inline-block;
    text-decoration: inherit;
    text-align: center;
    font-variant: normal;
    text-transform: none;
    line-height: 24px;
    font-size: 20px;
    width: 24px !important;
    height: 24px !important;
}
.sidebar-div:hover .sidebar-icon,
.sidebar-div:hover .sidebar-menu-text {
    color: var(--primary);
}

.sidebar-icon.icon-offers {
    font-size: 15px;
}

.sidebar-menu-text {
    font-weight: 600;
}

.is-selected .sidebar-icon,
.is-selected .sidebar-menu-text {
    color: var(--primary);
}
.sidebar-user.dropright .dropdown-toggle {
    background: transparent;
    border: none;
}
.dropright .dropdown-toggle::after {
    color: var(--text);
}
.sidebar-user.dropright .dropdown-toggle:focus,
.sidebar-user.dropright .dropdown-toggle:focus:active {
    outline: none;
    box-shadow: none;
}
.sidebar-user.dropright .dropdown-toggle:active {
    background: transparent;
}
.dropright .dropdown-item {
    font-size: 0.9rem;
    cursor: pointer;
    background-color: var(--bg-menu);
    color: var(--text);
    font-weight: 600;
}
.dropright .dropdown-item:hover {
    background: var(--secondary);
    color: var(--bg-menu);
}
.dropright .dropdown-item:active {
    background: var(--secondary);
    color: var(--bg-menu);
}

.sidebar-small .header-text,
.sidebar-small .sidebar-menu-text,
.sidebar-small .sidebar-username,
.sidebar-small .sidebar-email, 
.sidebar-small .sidebar-user {
    display: none;
}
.sidebar-small .user-logo {
    height: 28px;
    width: 28px;
    cursor: pointer;
}
.sidebar-small.sidebar-col{
    flex: 0 0 72px;
}
.sidebar-small .Sidebar{
    width: 72px;
}
.sidebar-small .header-logo{
    width: 44px;
}
.min-width-0{
    min-width: 0;
}
.version-text {
    font-size: 0.7rem; 
    margin-left: 0.2rem;
}
.navbar-mobile .MuiSvgIcon-root {
    fill: var(--nav-btn);
}
.navbar-mobile .MuiBottomNavigationAction-root.Mui-selected .MuiSvgIcon-root {
    fill: var(--primary);
}
.navbar-mobile .MuiBottomNavigationAction-root {
    color: var(--nav-btn);
}
.navbar-mobile .MuiBottomNavigationAction-root.Mui-selected {
    color: var(--primary)
}
.MuiPaper-rounded {
    border-radius: 0px !important;
}
.navbar-mobile .MuiBottomNavigation-root{
    min-height: 60px;
}
.navbar-mobile .MuiBottomNavigationAction-label{
    font-size: 0.65rem;
}
.navbar-mobile .MuiBottomNavigationAction-label.Mui-selected{
    font-size: 0.75rem;
    color: var(--primary)
}
.navbar-mobile .sidebar-icon, .menu-icon{
    font-size: 0.8rem;
}
.navbar-mobile .MuiBottomNavigationAction-root{
    max-width: 80px;
    min-width: 45px;
}
.navbar-mobile .sidebar-icon.icon-offers {
    font-size: 0.75rem;
}
.navbar-mobile .sidebar-icon.icon-toggle {
    font-size: 0.85rem;
}
.navbar-mobile .MuiToolbar-regular {
    min-height: 64px;
}
@media (min-width: 600px) {
    .navbar-mobile .MuiToolbar-regular {
        min-height: 64px;
    }
}
.version-text {
    font-size: 0.7rem; 
    margin-left: 0.2rem;
}
.main{
    min-height: 100vh;
    font-family: "Open Sans", sans-serif;
}
.main-header{
    background-color: var(--bg-menu);
    height: 60px;
}
.main-navigation{
    height: 70px;
}
.list-none{
    list-style: none;
    list-style-type: none;
}
li a {
    font-size: 0.8rem;
    color: var(--list-active);
    font-weight: 600;
    font-family: "Open Sans", sans-serif;
}
li a:hover{
    color: var(--list-active);
}
li a.previous-link{
    margin-right: 4px;
}
.previous-link:hover, .current-link:hover{
    text-decoration: none;
}
li a.current-link{
    color: var(--text);
}

.menu-h2-title{
    font-weight: 700;
    font-size: 1.15rem;
    letter-spacing: -0.25px;
}
.menu-h3-title{
    font-weight: 700;
    font-size: 0.8rem;
    letter-spacing: -0.25px;
}
.text-green{
    color: var(--green);
}
.text-red{
    color: var(--red);
}
.text-blue{
    color: var(--blue);
}
.text-orange{
    color: var(--orange);
}
.copyright{
    font-size: .85rem;
    margin-bottom: 2rem;
}
@media (min-width: 576px) {
    .copyright{
        font-size: 1rem;
    }
}
.SummaryStatistics .row h5 {
    font-size: 0.8rem;
}
.SummaryStatistics .row h4 {
    font-size: 1.1rem;
    font-weight: 700;
}
.Dashboard .row h5 {
    font-size: 0.8rem;
}
.Dashboard .row h4 {
    font-size: 1.1rem;
    font-weight: 700;
}
.block-trial {
    position: relative;
    height: 18px;
    width: 80px;
    margin-top: -13px;
    background-color: var(--bg-menu);
    overflow: hidden;
}
.offer-link {
    font-size: 0.8rem;
    color: var(--text);
    font-weight: 600;
}
.offer-link:hover {
    text-decoration: none;
    color: var(--secondary);
}
.chartContainer {
    height: 230px;
    width: 100%;
}
@media (min-width: 768px) {
    .chartContainer {
        height: 300px;
    }
}
@media (min-width: 1200px) {
    .chartContainer {
        height: 400px;
    }
}

.react-datepicker {
    background-color: var(--bg-menu);
    color: var(--text);
    border: 1px solid var(--border-defaul);
    border-radius: 0.3rem;
    display: inline-block;
    position: relative;
    box-shadow: var(--box-shadow);
}
.react-datepicker__header {
    background-color: var(--bg-menu);
    color: var(--text);
}

.react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected{
    background-color: var(--secondary);
    color: var(--text);
}

.react-datepicker__month-text.react-datepicker__month--selected:hover, .react-datepicker__month-text.react-datepicker__month--in-range:hover, .react-datepicker__month-text.react-datepicker__quarter--selected:hover, .react-datepicker__month-text.react-datepicker__quarter--in-range:hover, .react-datepicker__quarter-text.react-datepicker__month--selected:hover, .react-datepicker__quarter-text.react-datepicker__month--in-range:hover, .react-datepicker__quarter-text.react-datepicker__quarter--selected:hover, .react-datepicker__quarter-text.react-datepicker__quarter--in-range:hover{
    background-color: var(--secondary-light);
    color: var(--text);
}
.react-datepicker__month-text:hover, .react-datepicker__quarter-text:hover {
    background-color: var(--secondary-light);
    color: var(--text);
}
.myModal > .modal-dialog > .modal-content {
    background-color: var(--bg-menu);
    color: var(--text);
}
.comboChoice {
    border: 1px solid var(--gray);
}
.comboChoice:hover {
    cursor: pointer;
}
.comboChoiceSelected {
    border: 1px solid var(--primary);
}
.comboChoiceSelected:hover{
    cursor: pointer;
}

.react-contextmenu {
    background-color: var(--bg-menu);
    box-shadow: var(--box-shadow);
    color: var(--text);
    font-size: 0.85rem;
    border-radius: 0.25rem;
    z-index: 1;
}

.react-contextmenu-item {
    cursor: pointer;
    padding: 0.5rem;
}
.react-contextmenu-item:hover {
    cursor: pointer;
    background-color: var(--primary);
    border-radius: 0.25rem;
}

.search-input {
    padding-left: 16px;
    font-size: 0.8rem;
    height: 38px;
}
.img-offerlist {
    width:100%;
    height: auto;
}
.offerlist-link {
    font-size: 0.8rem;
    color: var(--text);
}
.offerlist-link:hover {
    text-decoration: none;
    color: var(--primary);
}
.img-responsive {
    max-width: 100%;
    height: 200px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: top;
    background-color: var(--bg-menu);
}
.priceText {
    font-size: 1.1rem;
    color: rgb(210, 210, 210);
    margin-right: 0.4rem;
}
.price {
    font-size: 1.7rem;
    color: rgb(255, 10, 20);
    margin-right: 0.4rem;
}
.text {
    font-size: 0.85rem;
}
.bold-text {
    font-weight: 700;
}
.w-200 {
    width: 200px;
}
.color-primary {
    color: var(--primary);
}
.border-top-link {
    border-top: 3px solid var(--bg-menu);
}
.border-bottom-thin-link {
    border-bottom: 2px solid var(--bg-block-inverse);
}
.border-primary-link {
    border-color: var(--primary);
}
.medium-text {
    font-size: 0.9rem;
}
.small-text-offer-details{
    font-size: 0.8rem;
}
.fade-out-transition {
    transition: opacity 0.3s ease-in-out;
}
.opacity-0 {
    opacity: 0;
}
.opacity-1 {
    opacity: 1;
}
.color-red {
    color: var(--red);
}
@media (min-width: 576px) {
    .img-responsive {
        max-width: 100%;
        width: auto;
        height: 250px;
    }
}

@media (min-width: 768px) {
    .img-responsive {
        max-width: 100%;
        width: auto;
        height: 260px;
    }
    .offer-admin .img-responsive{
        height: 340px;
    }
}

@media (min-width: 992px) {
    .img-responsive {
        max-width: 100%;
        width: auto;
        height: 275px;
    }
    .offer-admin .img-responsive{
        height: 400px;
    }
}

@media (min-width: 1200px) {
    .offer-admin .img-responsive{
        height: 400px;
    }
}

.collapse-link {
    font-size: 1.15rem;
    color: var(--text);
    font-weight: 700;
}
.collapse-link:hover {
    text-decoration: none;
    color: var(--primary);
}
.collapse-link:focus {
    text-decoration: none;
}
.card {
    border: none;
}
.card-header {
    background-color: var(--bg-menu);
}

.bg-datepicker {
    background-color: var(--bg-menu);
    box-shadow: var(--box-shadow);
    color: var(--text);
    border-color: var(--border-defaul);
    height: 38px;
}
.react-date-picker__inputGroup{
    text-align: center;
}
.date-form .bg-datepicker {
    box-shadow: none;
    border: 1px solid var(--border-default);
}
.react-date-picker {
    width: 100%;
    height: 100%;
}
.react-date-picker__wrapper {
    border: none;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    padding: 0px 8px 0px 16px;
}
.react-date-picker__button:focus {
    outline: none;
}
.react-daterange-picker__button .react-daterange-picker__button__icon {
    stroke: var(--text);
}
.react-date-picker__button:enabled:hover .react-date-picker__button__icon,
.react-date-picker__button:enabled:focus .react-date-picker__button__icon {
    stroke: var(--secondary);
}
.react-date-picker__inputGroup {
    justify-content: center;
    font-size: 0.8rem;
}
.react-date-picker__range-divider {
    font-size: 0.45rem;
    display: flex;
    align-items: center;
}
.react-calendar__tile--active {
    background: var(--secondary);
}
.react-calendar__tile--hasActive {
    background: var(--secondary-light);
}
.react-calendar__tile--hasActive:hover {
    background: var(--secondary-light);
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
    background: var(--secondary-light);
}
.react-date-picker__inputGroup__input {
    color: var(--text);
}
@media (min-width: 576px) {
    .react-date-picker__inputGroup {
        font-size: 0.5rem;
    }
    .react-date-picker__range-divider {
        font-size: 0.5rem;
    }
}
@media (min-width: 1200px) {
    .react-date-picker__inputGroup {
        font-size: 0.6rem;
    }
    .react-date-picker__range-divider {
        font-size: 0.6rem;
    }
}
@media (min-width: 1350px) {
    .react-date-picker__inputGroup {
        font-size: 0.8rem;
    }
    .react-date-picker__range-divider {
        font-size: 0.8rem;
    }
}
.react-calendar__navigation button {
    color: var(--text);
}
.react-calendar--selectRange .react-calendar__tile--hover {
    color: var(--gray);
}
.react-calendar__month-view__days__day--weekend {
    color: var(--red) !important;
}
.react-calendar button {
    color: var(--text);
}
.react-calendar {
    background-color: var(--bg-menu);
    border-color: var(--bg-menu);
}
.border-grey{
    border: 1px solid var(--border-default);
}

.tox .tox-editor-header{
    z-index: unset !important;
}
