.react-contextmenu {
    background-color: var(--bg-menu);
    box-shadow: var(--box-shadow);
    color: var(--text);
    font-size: 0.85rem;
    border-radius: 0.25rem;
    z-index: 1;
}

.react-contextmenu-item {
    cursor: pointer;
    padding: 0.5rem;
}
.react-contextmenu-item:hover {
    cursor: pointer;
    background-color: var(--primary);
    border-radius: 0.25rem;
}
