.main{
    min-height: 100vh;
    font-family: "Open Sans", sans-serif;
}
.main-header{
    background-color: var(--bg-menu);
    height: 60px;
}
.main-navigation{
    height: 70px;
}
.list-none{
    list-style: none;
    list-style-type: none;
}
li a {
    font-size: 0.8rem;
    color: var(--list-active);
    font-weight: 600;
    font-family: "Open Sans", sans-serif;
}
li a:hover{
    color: var(--list-active);
}
li a.previous-link{
    margin-right: 4px;
}
.previous-link:hover, .current-link:hover{
    text-decoration: none;
}
li a.current-link{
    color: var(--text);
}

.menu-h2-title{
    font-weight: 700;
    font-size: 1.15rem;
    letter-spacing: -0.25px;
}
.menu-h3-title{
    font-weight: 700;
    font-size: 0.8rem;
    letter-spacing: -0.25px;
}
.text-green{
    color: var(--green);
}
.text-red{
    color: var(--red);
}
.text-blue{
    color: var(--blue);
}
.text-orange{
    color: var(--orange);
}
.copyright{
    font-size: .85rem;
    margin-bottom: 2rem;
}
@media (min-width: 576px) {
    .copyright{
        font-size: 1rem;
    }
}