.collapse-link {
    font-size: 1.15rem;
    color: var(--text);
    font-weight: 700;
}
.collapse-link:hover {
    text-decoration: none;
    color: var(--primary);
}
.collapse-link:focus {
    text-decoration: none;
}
.card {
    border: none;
}
.card-header {
    background-color: var(--bg-menu);
}
